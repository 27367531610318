import Vue from "vue";
import vuetify from "./../../plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./../../plugins/ie-polyfills";

import Login from "./Login.vue";

Vue.config.productionTip = false;

new Vue({vuetify, render: h => h(Login)}).$mount("#app");
